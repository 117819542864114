@import '../../../../node_modules/@inter-ikea/cbf-re-ipex-dexf-lib-ui-components/build/variables.scss';
@import '../../../../node_modules/@inter-ikea/cbf-re-ipex-dexf-lib-ui-components/build/mixins.scss';

.Capability-container {
  display: flex;
  flex-flow: column nowrap;
  @include breakpoint(small) {
    flex-flow: row wrap;
    justify-content: space-between;
  }
}
