@import '../../../../node_modules/@inter-ikea/cbf-re-ipex-dexf-lib-ui-components/build/variables.scss';
@import '../../../../node_modules/@inter-ikea/cbf-re-ipex-dexf-lib-ui-components/build/mixins.scss';

.card__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f7f7f7;
  margin-bottom: 1rem;

  @include breakpoint(small) {
    width: 32%;
  }
  @include breakpoint(large) {
    width: 19%;
  }
  &.empty {
    visibility: hidden;
  }
}

.card__image {
  height: auto;
  width: 100%;

  .image__size {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
}

.card__body__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 400px;
  padding: 1rem 0.8rem;

  .card__text {
    p {
      margin: 0 0 1rem;
    }
    .card__servicename {
      font-size: 14px;
    }
  }

  .card__button {
    margin: 0 0 1.4rem;
    display: flex;
    justify-content: center;
  }
}
