@import '../../../../node_modules/@inter-ikea/cbf-re-ipex-dexf-lib-ui-components/build/variables.scss';
@import '../../../../node_modules/@inter-ikea/cbf-re-ipex-dexf-lib-ui-components/build/mixins.scss';

.text-container {
    max-width: 75%;
    margin-bottom: 1rem;
    .bottom-text {
        margin-top: 2rem;
    }
    @include breakpoint(small) {
        max-width: 75%;
    }
    @include breakpoint(large) {
        max-width: 50%;
    }
}